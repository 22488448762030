import React, { useContext, useState } from "react";
import Durations from "./Durations";
import { PlusIcon, TrendingUp } from "lucide-react";
import Button from "../../Components/common/Button";
import ListHeader from "../../Components/common/ListHeader";
import TableSekeleton from "../../Components/common/TableSekeleton";
import CardSkeleton from "../../Components/common/CardSkeleton";
import GenerateDurationModal from "./GenerateDuration";
import StatBox from "../../Components/common/StatBox";
import AddDurationModal from "./AddDuration";
import AddDates from "./AddDates";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { toast } from "sonner";
import AddOffDates from "./AddOffdates";
import { AuthContext } from "../../contexts/auth";
import SlotPerDay from "./SlotPerDay";
import AddExtraSlot from "./AddExtraSlot";


const fetchDuration = async (branchId, page = 1, limit = 10) => {
  const url = `${process.env.REACT_APP_API_URL}/v1/appointments/durationList?branch_id=${branchId}&page=${page}&limit=${limit}`

  const response = await fetch(url);
  if (!response.ok) {
    throw new Error("Error fetching Durations");
  }
  return response.json();
};


const fetchDurationById = async (id) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/v1/appointments/getByDurationId?id=${id}`
  );
  if (!response.ok) {
    throw new Error("Error fetching Durations");
  }
  return response.json();
};

const DeleteConfirmationModal = ({ isOpen, onClose, onCancel, onConfirm, loading }) => {
  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
      <div className="bg-white rounded-lg p-6 shadow-lg">
        <h2>Are you sure You want to delete this duration?</h2>
        <div className="flex justify-end mt-4">
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded m-4"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            className={`bg-red-500 text-white px-4 py-2 rounded m-4 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
            onClick={onConfirm}
            disabled={loading} // Disable button while loading
          >
            {loading ? "Deleting..." : "Confirm"}
          </button>
        </div>
      </div>
    </div>
  );
};

const Duration = () => {
  
  const [showAddModal, setShowAddModal] = useState(false);
  const [showGenerateModal, setShowGenerateModal] = useState(false);
  const [showAddModalDates, setShowAddModalDates] = useState(false);
  const [showAddModalOffDates, setShowAddModalOffDates] = useState(false);
  const [showExtraSlot, setShowExtraSlot] = useState(false);
  const [showSlotPerDay, setShowSlotPerDay] = useState(false)
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [editDurationData, setEditDurationData] = useState(null);
  const [type, setType] = useState();
  const [selectedBranch, setSelectedBranch] = useState("");
  const [deleteDurationModal, setDeleteDurationModal] = useState(false);
  const [deleteDurationId, setDeleteDurationId] = useState("");
  const [loading, setLoading] = useState(false); // New loading state for delete operation
  const {user} = useContext(AuthContext)
  const onCancelDeleteDuration = () => {
    setDeleteDurationId("");
    setDeleteDurationModal(false);
  };

  const onConfirmDurationDelete = async () => {
    setLoading(true); // Set loading to true
    try {
      const url = `${process.env.REACT_APP_API_URL}/v1/appointments/duration/${deleteDurationId}`;
      const method = "delete";
      const response = await axios({
        url,
        method,
      });
      if (response.status === 200) {
        if (response.data.status == "error") {
          toast.error(response.data.message);
        } else {
          toast.success(`Time Deleted successfully`);
          // Close the modal after successful deletion
          onCancelDeleteDuration(); 
        }
      } else {
        toast.error("Failed to delete duration. Please try again later.");
      }
    } catch (error) {
      toast.error("Failed to delete duration. Please try again later.");
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  const {
    data: durationData,
    error: durationError,
    isLoading: isLoadingDuration,
  } = useQuery({
    queryKey: ["branchData", user?.employee?.branch_id, currentPage, itemsPerPage],
    queryFn: () => fetchDuration(user?.employee?.branch_id, currentPage, itemsPerPage),
    enabled: !!user?.employee?.branch_id, // Only fetch if branchId is available
  });

  const handleAddModal = () => {
    setType("Add");
    setShowAddModal(!showAddModal);
  };
  const handleGenerateModal = () => {
    setShowGenerateModal(!showGenerateModal);
  };
  const handleAddModalDates = () => {
    setShowAddModalDates(!showAddModalDates);
  };
  const handleAddModalOffDates = () => {
    setShowAddModalOffDates(!showAddModalOffDates);
  };

  const handleExtraSlots = () => {
    setShowExtraSlot(!showExtraSlot);
  }
  const handleSlotPerDay = () => {
    setShowSlotPerDay(!showSlotPerDay);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const OnEdit = async (id) => {
    try {
      const duration = await fetchDurationById(id);
      setType("Edit");
      setEditDurationData(duration.data);
      setShowAddModal(true);
    } catch (error) {
      console.error("Error fetching duration for edit:", error);
    }
  };
  const OnDelete = (id) => {
    setDeleteDurationModal(true);
    setDeleteDurationId(id);
  };

  const SearchChange = (e) => {
    setSearchText(e);
  };

  if (durationError) {
    return <div>Error loading Durations: {durationError.message}</div>;
  }

  const totalPages = durationData
    ? Math.ceil(durationData?.totalItems / itemsPerPage)
    : 1;

  return (
    <div className="p-4 bg-green">
      <div className="flex px-4 justify-end space-x-4">

        <Button className="a" onClick={handleSlotPerDay}>
          <PlusIcon className="mr-2 h-4 w-4 " />
          Add Slot per Day
        </Button>
        <Button className="a" onClick={handleAddModalOffDates}>
          <PlusIcon className="mr-2 h-4 w-4 " />
          Add Off Date
        </Button>
        <Button className="a" onClick={handleExtraSlots}>
          <PlusIcon className="mr-2 h-4 w-4 " />
          Add Extra Slot Per Day
        </Button>
        <Button className="a" onClick={handleAddModal}>
          <PlusIcon className="mr-2 h-4 w-4 " />
          Add Duration
        </Button>
        <Button className="a" onClick={handleAddModalDates}>
          <PlusIcon className="mr-2 h-4 w-4 " />
          Add Dates
        </Button>
      </div>
      <div className="grid grid-cols-3 gap-4 p-4">
        {/* Display StatBoxes for Total, Active, and Working Duration */}
        {isLoadingDuration ? (
          <CardSkeleton />
        ) : (
          <StatBox
            fromColor={"from-primary"}
            toColor={" to-yellow-500"}
            icon={TrendingUp}
            stat={durationData?.totalItems}
            title={"Total Duration"}
          />
        )}
        {isLoadingDuration ? (
          <CardSkeleton />
        ) : (
          <StatBox
            fromColor={"from-primary"}
            toColor={" to-green-500"}
            icon={TrendingUp}
            stat={"42"}
            title={"Active Duration"}
          />
        )}
        {isLoadingDuration ? (
          <CardSkeleton />
        ) : (
          <StatBox
            fromColor={"from-primary"}
            toColor={" to-green-200"}
            icon={TrendingUp}
            stat={"42"}
            title={"Working Duration"}
          />
        )}
      </div>
      <div className="flex flex-col border rounded-lg border-gray-100 m-4">
        <ListHeader
          titleHeader={"Duration List"}
          setListLimit={setItemsPerPage}
          searchOnChange={handleSearchChange}
          setSearchQuery={SearchChange}
        />
        {isLoadingDuration ? (
          <TableSekeleton />
        ) : (
          <Durations
            durations={durationData?.data || []}
            onEdit={OnEdit}
            onDelete={OnDelete}
          />
        )}
        <nav aria-label="Page navigation example" className="flex justify-center py-2 ">
          <ul className="flex items-center -space-x-px h-8 text-sm">
            {Array.from({ length: totalPages }, (_, index) => (
              <li key={index}>
                <button
                  onClick={() => handlePageChange(index + 1)}
                  className={`flex items-center justify-center px-3 h-8 leading-tight ${
                    currentPage === index + 1
                      ? "text-blue-600 border border-blue-300 bg-blue-50"
                      : "text-gray-500 bg-white border border-gray-300"
                  } hover:bg-gray-100 hover:text-gray-700`}
                >
                  {index + 1}
                </button>
              </li>
            ))}
          </ul>
        </nav>
        {showAddModal && (
          <AddDurationModal
            handleAddModal={handleAddModal}
            type={type}
            durationData={editDurationData}
            branch_id={user?.employee?.branch_id}
          />
        )}
        {showSlotPerDay && (
          <SlotPerDay
            handleAddOfficerPerDay={handleSlotPerDay}
          />
        )}

        {showAddModalDates && (
          <AddDates handleAddModalDates={handleAddModalDates} />
        )}
        {showAddModalOffDates && (
          <AddOffDates branch_id={user?.employee?.branch_id} handleAddModalDates={handleAddModalOffDates} />
        )}
        {showExtraSlot && (
          <AddExtraSlot  branch_id={user?.employee?.branch_id}  handleAddExtraSlot={handleExtraSlots} />
        )}
        {deleteDurationModal && (
          <DeleteConfirmationModal
            onConfirm={onConfirmDurationDelete}
            onCancel={onCancelDeleteDuration}
            isOpen={deleteDurationModal}
            onClose={() => setDeleteDurationModal(false)}
            loading={loading} // Pass loading state to modal
          />
        )}
      </div>
    </div>
  );
};

export default Duration;
