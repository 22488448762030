import {
  ArrowsRightLeftIcon,
  BuildingStorefrontIcon,
  ChartPieIcon,
  CogIcon,
  TruckIcon,
} from "@heroicons/react/24/outline";
import Dashboard from "./dashboard";
import Fleet from "./manage-appointments";
import ManageStations from "./manage-organization";
import Profile from "./Profile/profile";
import Appointment from "./manage-appointments/Appointment";
import SlotDuration from "./SlotManagement";
import Branch from "./branch";
import Serv from "./Services";
import Payments from "./manage-payment";
import Location from "./location/index";
import BranchDetail from "./branch/BranchDetail";
import Duration from "./appointmentduration";
import ManageUser from "./manage-user";
import OfficerQueue from "./officerQueue";
import { Book, LocateFixedIcon, UsersIcon } from "lucide-react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import DashboardBranch from "./dashboardBranch";
import DashboardOrganization from "./dashboardOrganization";
import AgentReport from "./agent_report";
import InprogressQueue from "./inprogressQueue";
import AppointmentManager from "./check-appointments";
import CheckOutPage from "./checkout-appointments";
import WindowScreen from "./windowScreen";
import Booking from "./Booking";
import UpdateBranch from "./branch/UpdateBranch";
import BookForManager from "./BookForManager";
import BookingReport from "./booking_report";
import Review from "./review";
import AgentReview from "./agent_review";
import Attendance from "./attendance"
import RestaurantCheckin from "./RestaurantCheckin";
import RestaurantDashboard from "./RestaurantDashboard";
import { FaHome, FaMap } from "react-icons/fa";

const queryClient = new QueryClient();

const DefaultViews = {
  // "/profile": {
  //   label: "Settings",
  //   component: <Profile />,
  //   icon: CogIcon,
  // },
};

const RoleBasedViews = {
  "cb57b04b-3418-42b9-83e9-d770aa54875a": {
    name: "cheche_manager",
    routes: {
      "/": {
        label: "Dashboard",
        component: <Dashboard />,
        icon: ChartPieIcon,
      },
      "/Appointment": {
        label: "Booking",
        component: <Booking />,
        icon: UsersIcon,
      },
      "/agent_report": {
        label: "Agent Report",
        component: <AgentReport />,
        icon: UsersIcon,
      },
      "/booking_report": {
        label: "Booking Report",
        component: <BookingReport />,
        icon: UsersIcon,
      },
      "/organization": {
        label: "Organization",
        component: (
          <QueryClientProvider client={queryClient}>
            <ManageStations action_policies={["add", "edit"]} />
          </QueryClientProvider>
        ),
        icon: BuildingStorefrontIcon,
      },
      "/review": {
        label: "Reviews",
        component: <Branch action_policies={["add", "edit"]} />,
        icon: TruckIcon,
      },
      "/branch": {
        label: "Branch",
        component: <Branch action_policies={["add", "edit"]} />,
        icon: TruckIcon,
      },
      "/service": {
        label: "Services",
        component: <Serv action_policies={["add", "edit"]} />,
        icon: TruckIcon,
      },
      "/appointment": {
        label: "Appointments",
        component: <Fleet action_policies={["add", "edit"]} />,
        icon: TruckIcon,
      },
      "/appointmentduration": {
        label: "Appointment Setting",
        component: <Duration action_policies={["add", "edit"]} />,
        icon: UsersIcon,
        bool: true,
      },
      "/attendance_report": {
        label: "Attendance",
        component: <Attendance action_policies={["add", "edit"]} />,
        icon: UsersIcon,
        bool: true,
      },
      "/payment": {
        label: "Payments",
        component: (
          <QueryClientProvider client={queryClient}>
            <Payments action_policies={["add", "edit"]} />
          </QueryClientProvider>
        ),
        icon: ArrowsRightLeftIcon,
      },
      "/ManageUser": {
        label: "Users",
        component: (
          <QueryClientProvider client={queryClient}>
            <ManageUser action_policies={["add", "edit"]} />,
          </QueryClientProvider>
        ),
        icon: UsersIcon,
      },
      "/BranchDetail": {
        label: "Details",
        component: <BranchDetail action_policies={["add", "edit"]} />,
        icon: UsersIcon,
        bool: false,
      },
      "/location": {
        label: "Location",
        component: <Location action_policies={["add", "edit"]} />,
        icon: LocateFixedIcon,
      },
    },
  },
  "1a2c380d-76b9-441c-8860-91ab36757d84": {
    name: "system_admin",
    routes: {
      "/": {
        label: "Dashboard",
        component: <Dashboard />,
        icon: ChartPieIcon,
      },
      "/Appointment": {
        label: "Booking",
        component: <Booking />,
        icon: UsersIcon,
      },
      "/agent_report": {
        label: "Agent Report",
        component: <AgentReport />,
        icon: UsersIcon,
      },
      "/booking_report": {
        label: "Booking Report",
        component: <BookingReport />,
        icon: UsersIcon,
      },
      "/attendance_report": {
        label: "Attendance",
        component: <Attendance action_policies={["add", "edit"]} />,
        icon: UsersIcon,
        bool: true,
      },
      "/organization": {
        label: "Organization",
        component: (
          <QueryClientProvider client={queryClient}>
            <ManageStations action_policies={["add", "edit"]} />
          </QueryClientProvider>
        ),
        icon: BuildingStorefrontIcon,
      },
      "/review": {
        label: "Reviews",
        component: <Branch action_policies={["add", "edit"]} />,
        icon: TruckIcon,
      },
      "/branch": {
        label: "Branch",
        component: <Branch action_policies={["add", "edit"]} />,
        icon: TruckIcon,
      },
      "/service": {
        label: "Services",
        component: <Serv action_policies={["add", "edit"]} />,
        icon: TruckIcon,
      },
      "/appointment": {
        label: "Appointments",
        component: <Fleet action_policies={["add", "edit"]} />,
        icon: TruckIcon,
      },
      "/appointmentduration": {
        label: "Appointment Setting",
        component: <Duration action_policies={["add", "edit"]} />,
        icon: UsersIcon,
        bool: true,
      },
      "/ManageUser": {
        label: "Users",
        component: (
          <QueryClientProvider client={queryClient}>
            <ManageUser action_policies={["add", "edit"]} />,
          </QueryClientProvider>
        ),
        icon: UsersIcon,
      },
      "/BranchDetail": {
        label: "Details",
        component: <BranchDetail action_policies={["add", "edit"]} />,
        icon: UsersIcon,
        bool: false,
      },
      "/location": {
        label: "Location",
        component: <Location action_policies={["add", "edit"]} />,
        icon: LocateFixedIcon,
      },
    },
  },
  "b7dffb6d-8c49-4705-ae2b-ebd70555cac7": {
    name: "dars_manager",
    routes: {
      "/": {
        label: "Dashboard",
        component: <DashboardOrganization />,
        icon: ChartPieIcon,
      },
    },
  },
  "01bf91c3-abb9-4c5c-8b84-364dd28e8688": {
    name: "branch_manager",
    routes: {
      "/": {
        label: "Dashboard",
        component: <DashboardBranch />,
        icon: ChartPieIcon,
      },

      "/appointment": {
        label: "Appointments",
        component: <Fleet action_policies={["add", "edit"]} />,
        icon: TruckIcon,
      },
      "/appointmentduration": {
        label: "Manage Branch",
        component: <SlotDuration action_policies={["add", "edit"]} />,
        icon: UsersIcon,
        bool: true,
      },
      "/updateBranch": {
        label: "Update Branch",
        component: <UpdateBranch />,
        icon: TruckIcon,
      },
      "/book": {
        label: "Book",
        component: <BookForManager />,
        icon: Book,
      },
      "/branch_review": {
        label: "Branch Review",
        component: <AgentReview />,
        icon: Book,
      },

      "/BranchDetail": {
        label: "Details",
        component: <BranchDetail action_policies={["add", "edit"]} />,
        icon: UsersIcon,
        bool: false,
      },
    },
  },
  //UPdates here
  "af43ff03-13d4-4c22-ab35-ae9e59408b20": {
    name: "branch_officer",
    routes: {
      "/": {
        label: "Manage Queue",
        component: <OfficerQueue />,
        icon: ChartPieIcon,
      },
      "/inprogress_queues": {
        label: "InProgress Queues",
        component: <InprogressQueue />,
        icon: TruckIcon,
      },
    },
  },
  "f90db2ec-cfa3-45ed-8ee0-4321f061a7bc": {
    name: "check_in",
    routes: {
      "/": {
        label: "Check In",
        component: <AppointmentManager />,
        icon: ChartPieIcon,
      },
      "/Booking": {
        label: "Book",
        component: <Booking />,
        icon: UsersIcon,
      },
      "/checkOutAppointments": {
        label: "Check Out",
        component: <CheckOutPage />,
        icon: ChartPieIcon,
      },
      "/appointmentsList": {
        label: "Appointments",
        component: <Fleet />,
        icon: TruckIcon,
      },
    },
  },
  //Test
  "01bf91c3-abb9-4c5c-8b84-364dd28e8683": {
    name: "check_in",
    routes: {
      "/": {
        label: "Check In",
        component: <AppointmentManager />,
        icon: ChartPieIcon,
      },
      "/appointmentsList": {
        label: "Appointments",
        component: <Fleet />,
        icon: TruckIcon,
      },
    },
  },
  "01bf91c3-abb9-4c5c-8b84-364dd28e8689": {
    name: "check_out",
    routes: {
      "/": {
        label: "Checkout Appointments",
        component: <CheckOutPage />,
        icon: ChartPieIcon,
      },
    },
  },
  "94e2be4f-184f-4ecb-a5e3-087e8245d8cc": {
    name: "windows",
    routes: {
      "/": {
        label: "Home",
        component: <WindowScreen />,
        icon: ChartPieIcon,
      },
    },
  },
  // "94e2be4f-184f-4ecb-a5e3-087e8245d8cc": {
  //   name: "Restaurant_checkin",
  //   routes: {
  //     "/": {
  //       label: "Home",
  //       component: <RestaurantCheckin />,
  //       icon: ChartPieIcon,
  //     },
  //     "/floormap": {
  //       label: "Floor Map",
  //       // component:
  //       icon: FaMap,
  //     },
  //   },
  // },
  // "94e2be4f-184f-4ecb-a5e3-087e8245d8cc": {
  //   name: "Restaurant_Manager",
  //   routes: {
  //     "/": {
  //       label: "Dashboard",
  //       component: <RestaurantDashboard />,
  //       icon: FaHome,
  //     },
  //     "/reservations": {
  //       label: "Reservation",
  //       component: <RestaurantCheckin />,
  //       icon: ChartPieIcon,
  //     },
  //     "/floormap": {
  //       label: "Floor Map",
  //       // component:
  //       icon: FaMap,
  //     },
  //   },
  // },
};

export { DefaultViews, RoleBasedViews };
