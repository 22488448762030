import React, { useState, useEffect, useContext } from "react";
import QueueItem from "./Queueitem";
import { AuthContext } from "../../contexts/auth";
import axios from "axios";

function QueueManagement() {
  const [tickets, setTickets] = useState([]);
  const { user } = useContext(AuthContext);

  // Helper function to fetch in-progress queues
  const fetchInProgressQueues = async () => {
    try {
      const token = localStorage.getItem("token"); // Get the token from localStorage
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/queues/inProgressQueues`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const data = response.data.queues;
      setTickets(data);
    } catch (error) {
      console.error("Error fetching in-progress queues:", error);
    }
  };

  // Deduplicate tickets based on queue_no
  const deduplicateTickets = (tickets) => {
    const seen = new Set();
    return tickets.filter((ticket) => {
      if (seen.has(ticket.queue_no)) {
        return false;
      }
      seen.add(ticket.queue_no);
      return true;
    });
  };

  // Fetch queues periodically
  useEffect(() => {
    fetchInProgressQueues();
    const intervalId = setInterval(fetchInProgressQueues, 5000);
    return () => clearInterval(intervalId);
  }, [user]);

  // Announce the queues
  useEffect(() => {
    if (tickets.length === 0) return;

    const announceQueue = () => {
      if (!window.speechSynthesis) {
        console.warn("Speech synthesis not supported");
        return;
      }

      const speechQueue = [...tickets]; // Create a copy to manage announcements
      const announce = () => {
        if (speechQueue.length === 0) return;

        const ticket = speechQueue.shift(); // Get the first ticket in the queue
        const { queue_no, window_no } = ticket;
        const message = `Queue number ${queue_no}, please proceed to window number ${window_no}`;
        console.log("Announcing:", message);

        const speech = new SpeechSynthesisUtterance(message);
        speech.lang = "en-US";
        speech.rate = 1;

        speech.onend = () => {
          if (speechQueue.length > 0) announce(); // Announce the next ticket
        };

        window.speechSynthesis.speak(speech);
      };

      announce();
    };

    // Announce queues every 20 seconds
    const intervalId = setInterval(announceQueue, 20000);
    return () => clearInterval(intervalId);
  }, [tickets?.length]);

  // Deduplicate the tickets before rendering
  const deduplicatedTickets = deduplicateTickets(tickets);

  // Slice tickets for two columns
  const columns = 2;
  const rows = 5;
  const ticketsPerColumn = rows;

  const columnsData = Array.from({ length: columns }, (_, columnIndex) =>
    deduplicatedTickets.slice(columnIndex * ticketsPerColumn, (columnIndex + 1) * ticketsPerColumn)
  );

  return (
    <main className="flex w-screen flex-col pb-12 text-5xl font-bold bg-white">
      <header className="flex flex-wrap gap-5 justify-between max-w-full text-black w-[833px]">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/179c15d6afed0368dfbeeec2edb37fd683d8d92b08702b699637187ca0f1dd5b?apiKey=7d44a34051b049b6b7441f51555cf867&"
          className="object-contain shrink-0 aspect-[1.28] w-[95px]"
          alt=""
        />
        <h1 className="flex items-center justify-center ml-16 mt-7 ">
          Queue TV Screen
        </h1>
      </header>
      <section className="flex gap-5 px-20 mt-3 w-full text-white max-md:px-5 max-md:max-w-full">
        {columnsData.map((columnTickets, columnIndex) => (
          <div key={columnIndex} className="flex flex-col gap-2 flex-1">
            {columnTickets.map((ticket, index) => (
              <QueueItem
                key={index}
                number={ticket.queue_no}
                windowNo={ticket.window_no}
                firstname={ticket.cnr}
                isEven={index % 2 === 0}
              />
            ))}
          </div>
        ))}
      </section>
    </main>
  );
}

export default QueueManagement;
