import React, { useContext, useState } from "react";
import ServiceBarChart from "./ServiceBarChart/ServiceBarChart";
import BranchBarChart from "./BranchBarChart/BranchBarChart";
import PieChart from "./PieChart/PieChart";
import ServiceChart from "./ServiceChart";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import StatBox from "../../Components/common/StatBox";
import { FaClipboardCheck, FaGoogleWallet, FaRegClock } from "react-icons/fa";
import fetchAllAppointments from "./PieChart/fetchAllAppointments";
import fetchExpiredAppointments from "./PieChart/fetchExpiredAppointment"; // Import expired appointments function
import { AuthContext } from "../../contexts/auth";
import CardSkeleton from "../../Components/common/CardSkeleton";
import BookedThroughChart from "./BookedThroughChart/PieChart";
import AgentPerformanceTwo from "./AgentPerformance/AgentPerformance";
import Feedbackreport from "./FeedBackReport/PieChart";
import Monthlyappointment from "./MonthlyAppoitment/Monthlyappointment";
import AgentPerformance from "../../pages/dashboardBranch/AgentPerformance/AgentPerformance";

const fetchBranches = async () => {
  const url = `${process.env.REACT_APP_API_URL}/v1/branches`;
  const response = await axios.get(url);
  return response.data.data;
};

function Dashboard() {
  const { token } = useContext(AuthContext);
  const [startingDate, setStartingDate] = useState(null);
  const [endingDate, setEndingDate] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState("");
  const [appointmentCounts, setAppointmentCounts] = useState([]);
  const [officerPerformanceData, setOfficerPerformanceData] = useState([]);
  const [grayCount, setGrayCount] = useState(0);

  const fetchOfficerPerformance = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/appointments/officer-performance`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (
        response.data.status === "success" &&
        Array.isArray(response.data.data)
      ) {
        setOfficerPerformanceData(response.data.data); // Set the officer performance data
      } else {
        console.error("Unexpected response format:", response.data);
      }
      return response.data.data;
    } catch (error) {
      console.error("Error fetching officer performance data:", error);
    }
  };
  // Fetch branches data
  const { data: branchesData } = useQuery({
    queryKey: ["branches"],
    queryFn: fetchBranches,
  });

  const { data: fetchOfficerPerformanceForBranch = [] } = useQuery({
    queryKey: ["fetchOfficerPerformanceForBranch"],
    queryFn: fetchOfficerPerformance,
  });

  // Fetch all appointments data
  const { data: countsDataForBranch, isLoading: dataForBranchLoading } =
    useQuery({
      queryKey: [
        "fetchCountForBranch",
        token,
        startingDate,
        endingDate,
        selectedBranch,
      ],
      queryFn: ({ queryKey }) =>
        fetchAllAppointments(
          queryKey[1],
          dateChangeHandler(queryKey[2]),
          dateChangeHandler(queryKey[3]),
          queryKey[4]
        ),
    });

  // Fetch expired appointments data
  const { data: expiredAppointmentsData } = useQuery({
    queryKey: [
      "fetchExpiredAppointments",
      token,
      startingDate,
      endingDate,
      selectedBranch,
    ],
    queryFn: ({ queryKey }) =>
      fetchExpiredAppointments(
        queryKey[1], // token
        dateChangeHandler(queryKey[2]), // from_date
        dateChangeHandler(queryKey[3]), // to_date
        queryKey[4] // branch_id
      ),
    enabled: !!token, // Only fetch when token is available
  });

  // Helper function to format date
  const dateChangeHandler = (date) => {
    if (date === null) {
      return;
    }
    const year = date?.getFullYear();
    const month = (date?.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed, so add 1
    const day = date?.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-end mr-5">
        <select
          value={selectedBranch}
          onChange={(e) => setSelectedBranch(e.target.value)}
          className="w-64 p-1 border border-gray-300 rounded-md shadow-sm m-1"
        >
          <option value="">Select a branch.</option>
          {branchesData?.map((eachBranchData, index) => (
            <option key={index} value={eachBranchData.id}>
              {eachBranchData.name}
            </option>
          ))}
        </select>
        <DatePicker
          selected={startingDate}
          onChange={(date) => setStartingDate(date)}
          dateFormat="yyyy-MM-dd"
          monthsShown={1}
          className="w-70 border border-gray-300 rounded-lg p-4 m-1"
          placeholderText="Filter By Date From"
          isClearable
        />
        <DatePicker
          selected={endingDate}
          onChange={(date) => setEndingDate(date)}
          dateFormat="yyyy-MM-dd"
          monthsShown={1}
          className="w-70 border border-gray-300 rounded-lg p-4 m-1"
          placeholderText="Filter By Date To"
          isClearable
        />
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-4 lg:grid-cols-4 gap-4 m-4">
        {dataForBranchLoading ? (
          <CardSkeleton />
        ) : (
          <StatBox
            fromColor={"from-primary"}
            toColor={"to-yellow-500"}
            icon={FaGoogleWallet}
            title={"Total"}
            stat={
              countsDataForBranch?.reduce(
                (total, item) => total + Number(item.appointment_count || 0),
                0
              ) || 0
            }
            notes={
              "Coming Up: " +
                countsDataForBranch?.find((item) => item.status === "confirmed")
                  ?.appointment_count || 0
            }
          />
        )}
        {dataForBranchLoading ? (
          <CardSkeleton />
        ) : (
          <StatBox
            fromColor={"from-primary"}
            toColor={"to-green-500"}
            icon={FaRegClock}
            title={"Queue"}
            stat={
              countsDataForBranch?.find((item) => item.status === "checked in")
                ?.appointment_count || 0
            }
          />
        )}
        {dataForBranchLoading ? (
          <CardSkeleton />
        ) : (
          <StatBox
            fromColor={"from-primary"}
            toColor={"to-green-300"}
            icon={FaClipboardCheck}
            title={"Completed"}
            stat={
              countsDataForBranch?.find((item) => item.status === "checked out")
                ?.appointment_count || 0
            }
            notes={
              "Incompleted: " +
              (countsDataForBranch?.find((item) => item.status === "checked in")
                ?.appointment_count || 0)
            }
          />
        )}
        {dataForBranchLoading ? (
          <CardSkeleton />
        ) : (
          <StatBox
            fromColor={"from-primary"}
            toColor={"to-gray-300"} // Changed color to red to indicate "Expired"
            icon={FaClipboardCheck}
            title={"Expired"}
            stat={
              countsDataForBranch?.find((item) => item.status === "expired")
                ?.appointment_count || 0
            }
          />
        )}
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4">
        <div className="p-4 rounded bg-white shadow-lg">
          <PieChart
            startingDate={dateChangeHandler(startingDate)}
            endingDate={dateChangeHandler(endingDate)}
            selectedBranch={selectedBranch}
          />
        </div>
        <div className="p-4 rounded bg-white shadow-lg">
          <ServiceChart
            startingDate={dateChangeHandler(startingDate)}
            endingDate={dateChangeHandler(endingDate)}
            selectedBranch={selectedBranch}
          />
        </div>
        <div className="p-4 rounded bg-white shadow-lg">
          <BookedThroughChart
            startingDate={dateChangeHandler(startingDate)}
            endingDate={dateChangeHandler(endingDate)}
            selectedBranch={selectedBranch}
          />
        </div>
        <div className="p-4 rounded bg-white shadow-lg">
          <BranchBarChart
            startingDate={dateChangeHandler(startingDate)}
            endingDate={dateChangeHandler(endingDate)}
            selectedBranch={selectedBranch}
          />
        </div>
        {!selectedBranch && (
          <div>
            <div className="p-4 rounded bg-white shadow-lg">
              <AgentPerformanceTwo
                startingDate={dateChangeHandler(startingDate)}
                endingDate={dateChangeHandler(endingDate)}
                selectedBranch={selectedBranch}
              />
            </div>
            <div className="p-4 rounded bg-white shadow-lg">
              <ServiceBarChart
                startingDate={dateChangeHandler(startingDate)}
                endingDate={dateChangeHandler(endingDate)}
                selectedBranch={selectedBranch}
              />
            </div>
          </div>
        )}

        {selectedBranch && (
          <div className="p-4 rounded bg-white shadow-lg">
            <AgentPerformance data={officerPerformanceData} />
          </div>
        )}

        <div className="p-4 rounded bg-white shadow-lg">
          <Feedbackreport
            startingDate={dateChangeHandler(startingDate)}
            endingDate={dateChangeHandler(endingDate)}
            selectedBranch={selectedBranch}
          />
        </div>
        <div className="p-4 rounded bg-white shadow-lg">
          <Monthlyappointment
            startingDate={dateChangeHandler(startingDate)}
            endingDate={dateChangeHandler(endingDate)}
            selectedBranch={selectedBranch}
          />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
