import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const AttendanceDashboard = () => {
  const [todayData, setTodayData] = useState([]);
  const [monthData, setMonthData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [limit] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedRecords, setExpandedRecords] = useState({});

  const fetchAttendanceData = async (reportType, page = 1, search = "") => {
    try {
      const token = localStorage.getItem("token");
      if (!token) throw new Error("No token found in localStorage");

      const url = new URL(`${process.env.REACT_APP_API_URL}/v1/attendance/attendances/report`);
      url.searchParams.append("reportType", reportType);
      url.searchParams.append("page", page);
      url.searchParams.append("limit", limit);
      if (search) {
        url.searchParams.append("search", search);
      }

      const response = await fetch(url, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const result = await response.json();
      if (result.status === "success") return result.data;
      throw new Error(result.message || "Failed to fetch data");
    } catch (error) {
      throw new Error(error.message);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const todayReport = await fetchAttendanceData("today", currentPage, searchTerm);
        const monthReport = await fetchAttendanceData("month");

        setTodayData(todayReport.data);
        setMonthData(monthReport.data);
        setTotalPages(todayReport.pagination.totalPages);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, [currentPage, searchTerm]);

  // Monthly Attendance Chart Data
  const monthlyAttendanceData = {
    labels: Array.from({ length: 31 }, (_, i) => `Day ${i + 1}`),
    datasets: [
      {
        label: "Clocked In",
        data: Array(31).fill(0),
        backgroundColor: "rgba(75, 192, 192, 0.6)",
      },
      {
        label: "Clocked Out",
        data: Array(31).fill(0),
        backgroundColor: "rgba(255, 99, 132, 0.6)",
      },
    ],
  };

  monthData.forEach((record) => {
    const day = new Date(record.created_at).getDate() - 1;
    if (record.type === "clockedIn") {
      monthlyAttendanceData.datasets[0].data[day]++;
    } else if (record.type === "clockedOut") {
      monthlyAttendanceData.datasets[1].data[day]++;
    }
  });

  // Perform search when button is clicked
  const handleSearchClick = async () => {
    try {
      setLoading(true);
      const todayReport = await fetchAttendanceData("today", currentPage, searchTerm);
      setTodayData(todayReport.data);
      setTotalPages(todayReport.pagination.totalPages);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  if (loading) return <div className="p-6 text-center">Loading...</div>;
  if (error) return <div className="p-6 text-center text-red-500">Error: {error}</div>;

  return (
    <div className="p-6 bg-gray-50 min-h-screen">
      {/* Stats Cards */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="bg-white shadow-md p-6 rounded-lg text-center">
          <h3 className="text-lg font-semibold">Total Clocked In (Today)</h3>
          <p className="text-3xl font-bold text-blue-600">
            {todayData.filter((record) => record.type === "clockedIn").length}
          </p>
        </div>
        <div className="bg-white shadow-md p-6 rounded-lg text-center">
          <h3 className="text-lg font-semibold">Total Clocked Out (Today)</h3>
          <p className="text-3xl font-bold text-green-600">
            {todayData.filter((record) => record.type === "clockedOut").length}
          </p>
        </div>
        <div className="bg-white shadow-md p-6 rounded-lg text-center">
          <h3 className="text-lg font-semibold">Total Attendance (This Month)</h3>
          <p className="text-3xl font-bold text-purple-600">{monthData.length}</p>
        </div>
      </div>

      {/* Search Input */}
      <div className="mt-8 flex items-center">
        <input
          type="text"
          placeholder="Search by name..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full md:w-1/3 px-4 py-2 border rounded-lg"
        />
        <button
          onClick={handleSearchClick}
          className="ml-4 px-6 py-2 bg-blue-500 text-white rounded-lg"
        >
          Search
        </button>
      </div>

      {/* Today's Attendance Table */}
      <h2 className="text-2xl font-bold mt-6">Today's Attendance</h2>
      <div className="bg-white shadow-md rounded-lg overflow-hidden mt-4">
        <table className="w-full">
          <thead className="bg-gray-100">
            <tr>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">Name</th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">Mobile</th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">Type</th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">Time</th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">Action</th> {/* Add an Action column for the button */}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {todayData.map((record, index) => {
              const { firstname, lastname, mobile, type, created_at, id } = record;
              
              // Check if there are multiple records for the same person
              const userRecords = todayData.filter(r => r.firstname === firstname && r.lastname === lastname && r.mobile === mobile);
              
              // Check if the current record is the first of the group
              const isFirstRecord = index === todayData.findIndex(r => r.firstname === firstname && r.lastname === lastname && r.mobile === mobile);

              return (
                <>
                  {isFirstRecord && (
                    <tr key={id}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {firstname} {lastname}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{mobile}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{type}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {new Date(created_at).toLocaleTimeString()}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {userRecords.length > 1 && (
                          <button
                            onClick={() =>
                              setExpandedRecords((prevState) => ({
                                ...prevState,
                                [mobile]: !prevState[mobile],
                              }))
                            }
                            className="text-blue-500 hover:text-blue-700"
                          >
                            {expandedRecords[mobile] ? 'Hide Other Records' : 'Show Other Records'}
                          </button>
                        )}
                      </td>
                    </tr>
                  )}

                  {/* Show the expanded records if the user has clicked to show them */}
                  {expandedRecords[mobile] &&
                    userRecords
                      .filter((r) => r.id !== id) // Hide the original record as it's already displayed
                      .map((expandedRecord) => (
                        <tr key={expandedRecord.id}>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 pl-12">
                            {expandedRecord.firstname} {expandedRecord.lastname}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{expandedRecord.mobile}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{expandedRecord.type}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                            {new Date(expandedRecord.created_at).toLocaleTimeString()}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900"></td>
                        </tr>
                      ))}
                </>
              );
            })}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="mt-6 flex justify-center space-x-2">
        {Array.from({ length: totalPages }, (_, index) => {
          const pageNumber = index + 1;
          if (
            pageNumber === 1 ||
            pageNumber === totalPages ||
            (pageNumber >= currentPage - 2 && pageNumber <= currentPage + 2)
          ) {
            return (
              <button
                key={index}
                onClick={() => setCurrentPage(pageNumber)}
                className={`px-4 py-2 border rounded-lg ${
                  currentPage === pageNumber ? "bg-blue-500 text-white" : "bg-white"
                }`}
              >
                {pageNumber}
              </button>
            );
          } else if (pageNumber === currentPage - 3 || pageNumber === currentPage + 3) {
            return <span key={index} className="px-4 py-2">...</span>;
          } else {
            return null;
          }
        })}
      </div>

      {/* Monthly Attendance Chart */}
      <h2 className="text-2xl font-bold mt-10">Monthly Attendance</h2>
      <div className="bg-white shadow-md rounded-lg p-6">
        <Bar
          data={monthlyAttendanceData}
          options={{
            responsive: true,
            plugins: {
              legend: { position: "top" },
              title: { display: true, text: "Attendance Overview (This Month)" },
            },
          }}
        />
      </div>
    </div>
  );
};

export default AttendanceDashboard;
