import React, { useState } from "react";
import { BsThreeDots } from "react-icons/bs";
import { CiSettings } from "react-icons/ci";
import { IoIosArrowDown } from "react-icons/io";
import RestaurantTable from "./RestaurantTable";
import AddReservationModal from "./AddReservationModal";
import WalkinReservationModal from "./WalkinReservationModal";
import axios from "axios";
import { useMutation } from "@tanstack/react-query";

const AddReservation = async (reservationData) => {
  try {
    const token = localStorage("token");
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/v1/appointments/add_reservation`,
      reservationData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("the response is: ", response.data);
    return response.data;
  } catch (error) {
    console.log("The error is: ", error);
    return error;
  }
};

const ConfirmationModal = ({ reservationData, closeConfirmReservation }) => {
  const AddReservationMutation = useMutation({
    mutationFn: AddReservation,
    onSuccess: () => {},
    onError: () => {},
  });
  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
        <h3 className=" text-center text-xl font-semibold mb-4">
          Confirm Reservation.
        </h3>
        {console.log("Reservation data, ", reservationData)}
        <h4 className=" text-center text-xl font-semibold mb-4">
          Confrim Reservation For: {reservationData?.firstname}{" "}
          {reservationData?.lastname}
        </h4>
        <div className="flex justify-center mt-4">
          <button
            className="bg-gray-500 text-white px-4 py-2 rounded mr-2 hover:bg-gray-700"
            onClick={closeConfirmReservation}
          >
            Cancel
          </button>
          <button
            className={`bg-green-500 text-white px-4 py-2 rounded cursor-pointer hover:bg-green-800`}
            // onClick={handleConfirmCheckIn}
          >
            {"Confirm"}
          </button>
        </div>
      </div>
    </div>
  );
};

const Index = () => {
  //
  const [reservationModal, setReservationModal] = useState(false);
  const [walkinReservationModal, setWalkinReservationModal] = useState(false);
  const [confirmationMoadl, setConfirmationModal] = useState(false);
  const [reservationData, setReservationData] = useState(null);

  const openReservationModal = () => setReservationModal(true);
  const closeReservationModal = () => setReservationModal(false);
  const openWalkinReservationModal = () => setWalkinReservationModal(true);
  const closeWalkinReservationModal = () => setWalkinReservationModal(false);

  //

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [locationDropdownOpen, setLocationDropdownOpen] = useState(false);

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);
  const toggleLocationDropdown = () =>
    setLocationDropdownOpen(!locationDropdownOpen);

  const openConfirmationModal = (data) => {
    setReservationData(data);
    setConfirmationModal(true);
  };
  const closeConfirmationModal = () => {
    setReservationModal(false);
    setWalkinReservationModal(false);
    setReservationData(null);
    setConfirmationModal(false);
  };
  const [active, setActive] = useState("reservation");
  const data = [];
  return (
    <>
      <div className="mx-20">
        <div className="mt-16 flex justify-between">
          <div>
            <h1 className="text-start text-[30px]">Table Reservations</h1>
            <div className="flex items-center">
              <span className="absolute bg-green-400 p-1 rounded-full h-4 w-4"></span>
              <button
                onClick={toggleLocationDropdown}
                className="bg-gray-50 text-gray-800 px-4 py-2 rounded-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-200 focus:ring-offset-2"
              >
                <p className="ml-8 text-[15px] flex items-center">
                  Addis Ababa <IoIosArrowDown className="mx-2" />
                </p>
              </button>
            </div>
            {locationDropdownOpen && (
              <div className="absolute z-10 mt-2 w-52 bg-gray-50 border border-gray-300 rounded-md shadow-lg">
                <div className="px-4 py-2 text-gray-700 hover:bg-white cursor-pointer">
                  Location One
                </div>
                <div className="px-4 py-2 text-gray-700 hover:bg-white cursor-pointer">
                  Location Two
                </div>
              </div>
            )}
          </div>
          <div>
            <button className="p-4 bg-gray-100 rounded-full hover:bg-gray-200 hover:cursor-pointer mx-3 text-blue-600 text-[15px]">
              <BsThreeDots />
            </button>
            <button className="p-4 bg-gray-100 rounded-full hover:bg-gray-200 hover:cursor-pointer mx-3 text-blue-600 text-[15px]">
              Floor Plan View
            </button>
            <button className="p-4 bg-gray-100 rounded-full hover:bg-gray-200 hover:cursor-pointer mx-3 text-blue-600 text-[15px]">
              <div className="flex items-center">
                <CiSettings className="text-[18px] mx-1" /> Settings
              </div>
            </button>
          </div>
        </div>
        <div className="flex justify-between mt-10">
          <div className="flex items-center">
            <div
              onClick={() => setActive("reservation")}
              className={`mx-3 ${
                active === "reservation" ? "border-b-4 border-blue-600" : ""
              }`}
            >
              <h1
                className={`${
                  active === "reservation"
                    ? "text-blue-600 font-semibold"
                    : "text-gray-800"
                } hover:cursor-pointer hover:text-blue-600 text-[15px]`}
              >
                Reservations(2)
              </h1>
            </div>
            <div
              onClick={() => setActive("requests")}
              className={`mx-3 ${
                active === "requests" ? "border-b-4 border-blue-600" : ""
              }`}
            >
              <h1
                className={`${
                  active === "requests"
                    ? "text-blue-600 font-semibold"
                    : "text-gray-800"
                } hover:cursor-pointer hover:text-blue-600 text-[15px]`}
              >
                Requests
              </h1>
            </div>
          </div>
          <div className="flex items-center">
            <div className="relative">
              {/* Add New Button */}
              <button
                onClick={toggleDropdown}
                className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                <div className="flex items-center">
                  Add New <IoIosArrowDown className="text-15px ml-3" />
                </div>
              </button>

              {/* Dropdown Menu */}
              {isDropdownOpen && (
                <div className="absolute z-10 mt-2 w-36 bg-white border border-gray-300 rounded-md shadow-lg">
                  <div
                    className="px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer"
                    onClick={() => {
                      openReservationModal();
                      setIsDropdownOpen(false);
                    }}
                  >
                    Reservation
                  </div>
                  <div
                    className="px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer"
                    onClick={() => {
                      openWalkinReservationModal();
                      setIsDropdownOpen(false);
                    }}
                  >
                    Walk-in
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <RestaurantTable data={data} />
      </div>
      {reservationModal && (
        <AddReservationModal
          reservationModal={reservationModal}
          closeReservationModal={closeReservationModal}
          openConfirmationModal={openConfirmationModal}
          closeConfirmationModal={closeConfirmationModal}
        />
      )}
      {confirmationMoadl && (
        <ConfirmationModal
          reservationData={reservationData}
          closeConfirmReservation={closeConfirmationModal}
        />
      )}
      {walkinReservationModal && (
        <WalkinReservationModal
          walkinReservationModal={walkinReservationModal}
          closeWalkinReservationModal={closeWalkinReservationModal}
          openConfirmationModal={openConfirmationModal}
          closeConfirmationModal={closeConfirmationModal}
        />
      )}
    </>
  );
};

export default Index;
