import React, { useState } from "react";
import Button from "../../Components/common/Button";
import axios from "axios";
import { toast } from "sonner";
import TableSekeleton from "../../Components/common/TableSekeleton";
import Dates from "./Dates";
import { useQuery } from "@tanstack/react-query";
import LoadingSpinner from "../../Components/lib/LoadingSpinner";

const fetchDuration = async (page, limit, query) => {
  let url = query
    ? `${process.env.REACT_APP_API_URL}/v1/appointments/durationList?page=${1}&limit=${400}&branch_id=${query}`
    : `${process.env.REACT_APP_API_URL}/v1/appointments/durationList?page=${1}&limit=${400}`;
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error("Error fetching Durations");
  }
  return response.json();
};

const fetchExtraSlots = async (page, limit, branch_id = null) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/v1/extra_slots?page=${page}&limit=${limit}&branch_id=${branch_id}`
  );
  if (!response.ok) {
    throw new Error("Error fetching extra slots");
  }
  return response.json();
};

const fetchBranches = async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/v1/branches/`
  );
  return response.data.data;
};

const AddExtraSlot = ({ branch_id, handleAddExtraSlot }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(4);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [isLoading, setIsLoading] = useState(false);


  const [formData, setFormData] = useState({
    branch_id: branch_id,
    date: "",
    appointment_duration_id: null,
    extra_slot: 0,
  });

  const { data: dates, isLoading: isLoadingDates, refetch } = useQuery({
    queryKey: ["ExtraSlots", currentPage, itemsPerPage, selectedBranch],
    queryFn: () => fetchExtraSlots(currentPage, itemsPerPage, selectedBranch),
  });

  const { data: branches, isLoading: isLoadingBranches } = useQuery({
    queryKey: ["branches"],
    queryFn: fetchBranches,
  });

  const {data: durations, isLoading: isLoadingDurations } = useQuery({
    queryKey: ["durations", 1, 300, selectedBranch],
    queryFn: () => fetchDuration(currentPage, itemsPerPage, selectedBranch),
  });

  //flat the arrary please for duriation



  const totalPages = dates ? Math.ceil(dates?.totalItems / itemsPerPage) : 1;

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleBranchChange = (e) => {
    const selectedBranchId = e.target.value;
    setSelectedBranch(selectedBranchId);
    setFormData({ ...formData, branch_id: selectedBranchId });
    refetch();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    console.log("post data", formData)
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/extra_slots`,
        formData
      );
      if (response.status === 200 || response.status === 201) {
        if (response.data.statusCode === 1) {
          toast.success("Extra slot added successfully");
          refetch();
        } else {
          toast.error(response.data.message);
        }
      } else {
        toast.error("Failed to add extra slot.");
      }
    } catch (error) {
      toast.error("Failed to add extra slot. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-transparent backdrop-blur-sm z-50">
      <div className="relative p-4 w-full max-w-3xl max-h-full">
        <div className="relative bg-white rounded-lg shadow-lg">
          <div className="flex items-center justify-between p-4 border-b border-gray-300">
            <h3 className="text-lg font-semibold text-gray-900">
              Add Extra Slot
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-700 hover:text-white rounded-lg text-sm w-8 h-8"
              onClick={handleAddExtraSlot}
            >
              <svg
                className="w-3 h-3"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
            </button>
          </div>
          <form className="p-4" onSubmit={handleSubmit}>
            <div className="grid gap-4 mb-4 grid-cols-2">
              {/* <div className="col-span-2 sm:col-span-1">
                <label
                  htmlFor="branch_id"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Branch
                </label>
                {isLoadingBranches ? (
                  <p>Loading branches...</p>
                ) : (
                  <select
                    name="branch_id"
                    id="branch_id"
                    className="bg-gray-100 border border-gray-300 rounded-lg w-full p-2.5"
                    value={formData.branch_id}
                    onChange={handleBranchChange}
                    required
                  >
                    <option value="">Select branch</option>
                    {branches.map((branch) => (
                      <option key={branch.id} value={branch.id}>
                        {branch.name}
                      </option>
                    ))}
                  </select>
                )}
              </div> */}
              <div className="col-span-2 sm:col-span-1">
                <label
                  htmlFor="date"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Date
                </label>
                <input
                  type="date"
                  name="date"
                  id="date"
                  className="bg-gray-100 border border-gray-300 rounded-lg w-full p-2.5"
                  value={formData.date}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-span-2 sm:col-span-1">
                <label
                  htmlFor="time"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Duration Time
                </label>
                <select
                  name="appointment_duration_id"
                  id="time"
                  className="bg-gray-100 border border-gray-300 rounded-lg w-full p-2.5"
                  value={formData.appointment_duration_id}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select time</option>
                  {isLoadingDurations ? (
                    <option value="">Loading...</option>
                  ) : durations?.data?.map((duration) => (
                    <option key={duration.id} value={duration.id}>
                      {duration.time_from}-{duration.time_to}
                    </option>
                  ))}

                </select>

              </div>
              <div className="col-span-2 sm:col-span-1">
                <label
                  htmlFor="extra_slots"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Extra Slots
                </label>
                <input
                  type="number"
                  name="extra_slot"
                  id="extra_slots"
                  className="bg-gray-100 border border-gray-300 rounded-lg w-full p-2.5"
                  value={formData.extra_slot}
                  onChange={handleChange}
                  min="1"
                  required
                />
              </div>
            </div>
            <Button type="submit">
              {isLoading ? <LoadingSpinner /> : "Add Extra Slot"}
            </Button>
          </form>
          {isLoadingDates ? (
            <TableSekeleton />
          ) : (
            <Dates
              dates={dates?.data?.data || []}
              type={"extraSlot"}
              onDelete={(id) => console.log(`Delete slot ID: ${id}`)}
            />
          )}
          <nav aria-label="Page navigation" className="py-2 flex justify-center">
            <ul className="flex items-center -space-x-px h-8">
              {Array.from({ length: totalPages }, (_, index) => (
                <li key={index}>
                  <button
                    onClick={() => handlePageChange(index + 1)}
                    className={`px-3 h-8 ${
                      currentPage === index + 1
                        ? "text-blue-600 bg-blue-50"
                        : "text-gray-500 bg-white"
                    }`}
                  >
                    {index + 1}
                  </button>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default AddExtraSlot;