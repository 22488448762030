import React, { useState } from "react";
import { CiCircleMore, CiExport, CiFilter } from "react-icons/ci";
import { FaCalendarAlt } from "react-icons/fa";
import { GiSettingsKnobs } from "react-icons/gi";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const ReservationData = [
  {
    date: "2025-01-29",
    time: "06:00 PM",
    name: "MOFA",
    partySize: "17",
    tableName: "PDR 101",
    status: "Reserved",
    phoneNumber: "+251912131415",
    teamNote: "-",
  },
  {
    date: "2025-01-31",
    time: "09:00 PM",
    name: "Ramiz Alsabrov",
    partySize: "3",
    tableName: "Table 10",
    status: "Seated",
    phoneNumber: "+251912131415",
    teamNote: "-",
  },
  {
    date: "2025-01-29",
    time: "06:00 PM",
    name: "MOFA",
    partySize: "17",
    tableName: "PDR 101",
    status: "Reserved",
    phoneNumber: "+251912131415",
    teamNote: "-",
  },
  {
    date: "2025-01-31",
    time: "09:00 PM",
    name: "Ramiz Alsabrov",
    partySize: "3",
    tableName: "Table 10",
    status: "Seated",
    phoneNumber: "+251912131415",
    teamNote: "-",
  },
];

const RestaurantTable = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Status");

  const options = [
    { value: "Reserved", label: "Reserved", color: "blue-500" },
    { value: "Seated", label: "Seated", color: "green-500" },
    { value: "Finished", label: "Finished", color: "pink-900" },
    { value: "No-Show", label: "No-Show", color: "gray-700" },
    { value: "Canceled", label: "Canceled", color: "gray-950" },
  ];

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = (option) => {
    setSelectedOption(option.label);
    setIsOpen(false);
  };

  //

  const [selectedDate, setSelectedDate] = useState(new Date());

  return (
    <div className="p-8 ">
      <div className="overflow-x-auto bg-white rounded-3xl p-5 border">
        <div className="flex justify-between items-center mt-5 mb-3 max-w-[95%]">
          <div className="relative">
            {/* DatePicker Input */}
            <DatePicker
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              dateFormat="yyyy-MM-dd"
              placeholderText="Select Date"
              className="pl-10 pr-4 py-2 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 w-full"
            />

            {/* Calendar Icon */}
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <FaCalendarAlt className="text-gray-400" />
            </div>
          </div>
          <div className="flex items-center">
            <button className="bg-gray-100 hover:bg-gray-200 text-blue-600 mx-2 rounded-full p-3 text-[13px]">
              <div className="flex items-center">
                <CiFilter className="text-[10px] mr-2" /> Filter
              </div>
            </button>
            <button className="bg-gray-100 hover:bg-gray-200 text-blue-600 mx-2 rounded-full p-3 text-[13px]">
              <CiExport className="text-[16px] font-bold" />
            </button>
            <button className="bg-gray-100 hover:bg-gray-200 text-blue-600 mx-2 rounded-full p-3 text-[15px]">
              <GiSettingsKnobs className="text-[16px] font-bold" />
            </button>
            <input
              type="text"
              placeholder="Search.."
              className="px-2 py-2 border border-gray-300 rounded-full min-w-[60%] mx-1"
            />

            {/* <button className="px-4 py-2 bg-blue-500 text-white rounded-md sm:w-auto w-full mx-3 shadow-md shadow-blue-400">
              Search
            </button> */}
          </div>
        </div>
        <table className="min-w-full border-collapse">
          <thead className="bg-blue-100 mt-5">
            <tr>
              <th className="px-4 py-2 text-left text-gray-800 font-medium">
                Date
              </th>
              <th className="px-4 py-2 text-left text-gray-800 font-medium">
                Time
              </th>
              <th className="px-4 py-2 text-left text-gray-800 font-medium">
                Name
              </th>
              <th className="px-4 py-2 text-left text-gray-800 font-medium">
                Partiy Size
              </th>
              <th className="px-4 py-2 text-left text-gray-800 font-medium">
                Table Name
              </th>
              <th className="px-4 py-2 text-left text-gray-800 font-medium">
                Status
              </th>
              <th className="px-4 py-2 text-left text-gray-800 font-medium">
                Phone Number
              </th>
              <th className="px-4 py-2 text-left text-gray-800 font-medium">
                Team Note
              </th>
              <th className="px-4 py-2 text-left text-gray-800 font-medium">
                Action
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {ReservationData.map((item, index) => (
              <tr key={index}>
                <td className="px-4 py-2 text-gray-700 font-medium">
                  {item.date}
                </td>
                <td className="px-4 py-2 text-gray-500">{item.time}</td>
                <td className="px-4 py-2 text-gray-500">{item.name}</td>
                <td className="px-4 py-2 text-gray-500">{item.partySize}</td>
                <td className="px-4 py-2 text-gray-500">{item.tableName}</td>
                <td className="px-4 py-2">
                  <select
                    value={item.status}
                    className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-2 px-4 pr-8 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  >
                    {options.map((option) => (
                      <option
                        key={option.value}
                        value={option.value}
                        className={`text-${option.color}`}
                      >
                        {option.label}
                      </option>
                    ))}
                  </select>
                </td>
                <td className="px-4 py-2 text-gray-500">{item.phoneNumber}</td>
                <td className="px-4 py-2 text-gray-500">{item.teamNote}</td>
                <td className="px-4 py-2 text-gray-500">
                  <button
                    className="p-2 py-1 text-gray-500 hover:text-gray-700 text-xl"
                    // onClick={() => onDetails(item)}
                  >
                    <CiCircleMore />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="mt-4 flex justify-between items-center">
        <button className="px-4 py-2 text-sm text-gray-500 hover:text-gray-700">
          Previous
        </button>
        <div className="flex gap-2">
          {[1, 2, 3, 4, 5].map((page) => (
            <button
              key={page}
              className={`px-3 py-1 text-sm rounded-md ${
                page === 3
                  ? "bg-blue-500 text-white"
                  : "text-gray-500 hover:text-gray-700"
              }`}
            >
              {page}
            </button>
          ))}
        </div>
        <button className="px-4 py-2 text-sm text-gray-500 hover:text-gray-700">
          Next
        </button>
      </div>
    </div>
  );
};

export default RestaurantTable;
