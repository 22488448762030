import React, { useState } from "react";
import { CiCalendar, CiForkAndKnife } from "react-icons/ci";
import { LuUsers } from "react-icons/lu";
import { MdOutlineTableRestaurant } from "react-icons/md";
import { RiTimerLine } from "react-icons/ri";
import { IoTimeOutline } from "react-icons/io5";
import axios from "axios";

const getAvailableTimeSlots = async (
  branch_id,
  branch_service_id,
  appointment_date
) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/v1/appointments/availableTimeSlotsByDateHotelReservation?branch_id=${branch_id}&branch_service_id=${branch_service_id}&appointment_date=${appointment_date}&slot=paid`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("the response is : ", response.data);
    return response.data;
  } catch (error) {
    console.log("Error: ", error);
  }
};

const GetTableWithDurationId = async (
  branch_id,
  branch_service_id,
  appointment_date,
  appointmentDurationId
) => {
  try {
    const token = localStorage("token");
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/v1/appointments/availableTableHotelReservation?branch_id=${branch_id}&branch_service_id=${branch_service_id}&appointment_date=${appointment_date}&appointment_duration_id=${appointmentDurationId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("The response of get table with duration is: ", response.data);
    return response.data;
  } catch (error) {
    console.log("I found the error: ", error);
    return error;
  }
};

const AddReservationModal = ({
  reservationModal,
  closeReservationModal,
  openConfirmationModal,
  closeConfirmationModal,
}) => {
  const [reservationData, setReservationData] = useState({
    partysize: "2",
    date: "",
    startTime: "",
    duration: "",
    table: "",
    status: "",
    firstname: "",
    lastname: "",
    phonenumber: "",
    email: "",
    teamNote: "",
  });

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setReservationData({ ...reservationData, [name]: value });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    openConfirmationModal(reservationData);
  };

  return (
    <div className="p-8">
      {/* Slide-In Modal */}
      <div
        className={`fixed inset-0 z-50 overflow-hidden  transition-shadow ${
          reservationModal ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
      >
        {/* Modal Backdrop */}
        <div
          className="fixed inset-0 bg-black bg-opacity-50"
          onClick={closeReservationModal}
        ></div>

        {/* Modal Content */}
        <div
          className={`fixed right-0 top-0 h-full w-1/3 bg-white shadow-lg transform transition-transform ${
            reservationModal ? "translate-x-0" : "translate-x-full"
          }`}
        >
          {/* Modal Header */}
          <div className="flex justify-between items-center p-4 border-b border-gray-200">
            <h2 className="text-[18px] text-blue-500">New Reservation</h2>
            <button
              onClick={closeReservationModal}
              className="text-gray-500 hover:text-gray-700"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          {/* Modal Body (Form) */}
          <div className="p-4 overflow-auto">
            <form onSubmit={submitHandler}>
              <h3 className="text-blue-500 text-[15px]">Reservation Details</h3>
              <div className="space-y-4 mt-5">
                {/* Party Size Input */}
                <div className="flex items-center justify-between">
                  <label className=" text-[13px] font-medium text-gray-700 mb-1 flex items-center">
                    <LuUsers className="mx-3" /> Party size
                  </label>
                  <input
                    type="number"
                    name="partysize"
                    onChange={changeHandler}
                    placeholder="2 guests"
                    className="w-[50%] px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div className="flex items-center justify-between">
                  <label className=" text-[13px] font-medium text-gray-700 mb-1 flex items-center">
                    <CiCalendar className="mx-3" /> Date
                  </label>
                  <input
                    type="date"
                    name="date"
                    onChange={changeHandler}
                    value={reservationData?.date}
                    className="w-[50%] px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div className="flex items-center justify-between">
                  <label className=" text-[13px] font-medium text-gray-700 mb-1 flex items-center">
                    <IoTimeOutline className="mx-3" />
                    Start Time
                  </label>
                  <input
                    type="time"
                    name="startTime"
                    onChange={changeHandler}
                    value={reservationData?.startTime}
                    className="w-[50%] px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>

                {/* Duration Input */}
                <div className="flex items-center justify-between">
                  <label className="text-[13px] font-medium text-gray-700 mb-1 flex items-center">
                    <RiTimerLine className="mx-3" /> Duration
                  </label>
                  <input
                    type="text"
                    name="duration"
                    onChange={changeHandler}
                    placeholder="1 hr 30 min"
                    className="w-[50%] px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>

                {/* Table Input */}
                <div className="flex items-center justify-between">
                  <label className="text-[13px]  font-medium text-gray-700 mb-1 flex items-center">
                    <MdOutlineTableRestaurant className="mx-3" /> Table
                  </label>
                  <input
                    type="text"
                    name="table"
                    onChange={changeHandler}
                    placeholder="11 x"
                    className="w-[50%] px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div className="flex items-center justify-between">
                  <label className="text-[13px]  font-medium text-gray-700 mb-1 flex items-center">
                    <CiForkAndKnife className="mx-3" /> Status
                  </label>
                  <select
                    onChange={changeHandler}
                    name="status"
                    className="w-[50%] px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value="reserved">Reserved</option>
                    <option value="seated">Seated</option>
                    <option value="finished">Finished</option>
                    <option value="noshow">No-Show</option>
                    <option value="canceled">Canceled</option>
                  </select>
                </div>
              </div>

              <h3 className="text-blue-500 text-[15px] mb-4">Guest Details</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    First Name
                  </label>
                  <input
                    type="text"
                    required
                    name="firstname"
                    onChange={changeHandler}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Last Name
                  </label>
                  <input
                    type="text"
                    name="lastname"
                    onChange={changeHandler}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    name="phonenumber"
                    onChange={changeHandler}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Email
                  </label>
                  <input
                    type="tel"
                    name="email"
                    onChange={changeHandler}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
              </div>
              <h3 className="text-blue-500 text-[15px] mb-4">Team notes</h3>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Team Note
                </label>
                <textarea
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  rows="3"
                  name="textNote"
                  onChange={changeHandler}
                ></textarea>
              </div>

              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={closeReservationModal}
                  className="mr-2 px-4 py-2 text-gray-700 bg-gray-100 hover:bg-gray-200  rounded-full"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-500 text-white hover:bg-blue-600 rounded-full"
                >
                  Create Reservation
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddReservationModal;
