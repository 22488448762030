import React, { useState, useEffect } from "react";
import axios from "axios";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import Button from "./Button"; // Ensure you have a reusable Button component

const fetchCategories = async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/categories`);
  return response.data;
};

const fetchOrganization = async (organizationId) => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/v1/organizations/${organizationId}`
  );
  return response.data;
};

const Modal = ({ isOpen, onClose, organizationId, onEditSuccess }) => {
  const [organizationName, setOrganizationName] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [logo, setLogo] = useState(null);
  const [background, setBackground] = useState(null);

  const queryClient = useQueryClient();

  const { data: categories, isLoading: isCategoriesLoading } = useQuery({
    queryKey: ["categories"],
    queryFn: fetchCategories,
  });

  const { data: organization, isLoading: isOrganizationLoading } = useQuery({
    queryKey: ["organization", organizationId],
    queryFn: () => fetchOrganization(organizationId),
    enabled: !!organizationId,
  });


  useEffect(() => {
    if (organization && organizationId) {
      setOrganizationName(organization.name || "");
      setDescription(organization.description || "");
      setStatus(organization.active || true);
      setSelectedCategory(organization.category_id || "");
      if (organization.logo) setLogo(organization.logo);
      if (organization.background) setBackground(organization.background);
    }
  }, [organization, organizationId]);

  const resetState = () => {
    setOrganizationName("");
    setDescription("");
    setStatus(true);
    setSelectedCategory("");
    setLogo(null);
    setBackground(null);
    onClose();
  };

  const handleFileChange = (event, setFile) => {
    const file = event.target.files[0];
    if (file) {
      setFile(file);
    }
  };

  const handleImageUpload = async (organizationId, imageFile, type) => {
    if (!imageFile) return;

    const formData = new FormData();
    formData.append(type, imageFile); // The key 'logo' or 'background' should match backend

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/images/${type}/${organizationId}`, // Corrected URL
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      console.log(`${type} uploaded successfully:`, response.data);
    } catch (error) {
      console.error(`Error uploading ${type}:`, error.response?.data || error.message);
    }
  };


  const organizationMutation = useMutation({
    mutationFn: async (formData) => {
      const url = organizationId
        ? `${process.env.REACT_APP_API_URL}/v1/organizations/edit?id=${organizationId}`
        : `${process.env.REACT_APP_API_URL}/v1/organizations`;

      return axios.post(url, formData);
    },
    onSuccess: async (data) => {
      const createdOrganizationId = data.data.id;

      // Upload logo and background
      if (logo) await handleImageUpload(createdOrganizationId, logo, "logo");
      if (background) await handleImageUpload(createdOrganizationId, background, "background");

      queryClient.invalidateQueries(["organizations"]);
      resetState();
      if (onEditSuccess) onEditSuccess();
    },
    onError: (error) => {
      console.error("Error submitting form:", error);
    },
  });

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    organizationMutation.mutate({
      name: organizationName,
      description,
      active: status,
      category_id: selectedCategory,
    });
  };

  if (!isOpen) return null;

  if (organizationId && isOrganizationLoading) {
    return <div>Loading organization data...</div>;
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-md">
        <h2 className="text-lg font-semibold mb-4">
          {organizationId ? "Edit " : "Add "}Organization
        </h2>
        <form onSubmit={handleFormSubmit}>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Organization Name
            </label>
            <input
              type="text"
              value={organizationName}
              onChange={(e) => setOrganizationName(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Description</label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
              rows={3}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Category</label>
            <select
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
              required
            >
              <option value="">Select a category</option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Logo</label>
            <input
              type="file"
              onChange={(e) => handleFileChange(e, setLogo)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
              accept="image/*"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Background</label>
            <input
              type="file"
              onChange={(e) => handleFileChange(e, setBackground)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
              accept="image/*"
            />
          </div>
          {organizationId && (
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Status</label>
              <select
                value={status ? "true" : "false"}
                onChange={(e) => setStatus(e.target.value === "true")}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
              >
                <option value="true">Active</option>
                <option value="false">Inactive</option>
              </select>
            </div>
          )}
          <div className="flex justify-end">
            <Button
              type="button"
              className="text-gray-500 hover:text-gray-700 mr-4"
              onClick={resetState}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="bg-blue-600 text-white px-4 py-2 rounded-md"
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Modal;
